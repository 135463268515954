import translations from "../data/translations.json" assert {type: 'json'};

const lang = location.pathname.split('/')[1];

/**
 * @param {string} planCode 
 * @returns {string | undefined}
 */
export function translatePlanCode(planCode) {
    return getTranslationsForCurrentLanguage(translations.planNames)?.[planCode];
}

/**
 * @param {string} planCode 
 * @returns {string | undefined}
 */
export function translatePlanBadgeText(planCode) {
    return getTranslationsForCurrentLanguage(translations.planBadges)?.[planCode];
}

/**
 * @param {string} invoiceState 
 * @returns {string}
 */
export function translateInvoiceState(invoiceState) {
    return getTranslationsForCurrentLanguage(translations.invoiceState)?.[invoiceState];
}

/**
 * @param {string} planState 
 * @returns {string}
 */
export function translatePlanState(planState) {
    return getTranslationsForCurrentLanguage(translations.planStatuses)?.[planState];
}

/**
 * @param {string} planState 
 * @returns {string}
 */
export function translatePlanInfos(planState) {
    return getTranslationsForCurrentLanguage(translations.planInfoText)?.[planState];
}

/**
 * @param {string} cvBuildLang 
 * @returns {string}
 */
export function translateCVBuilderLanguage(cvBuildLang) {
    return getTranslationsForCurrentLanguage(translations.langTranslations)?.[cvBuildLang];
}

/**
 * @param {{[lang: string]: object}} data 
 * @returns {object}
 */
function getTranslationsForCurrentLanguage(data) {
    return data[lang] ?? data['en'] ?? Object.values(data)[0];
}