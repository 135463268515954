import "../../../public/frontend/js/jquery-3.5.1.min.js";
import "../../../js/configmodule.js";

export const LOCAL_STORAGE_TOKEN = '_local_Storage_Token';

$(document).on( "ajaxError", function(event, jqXHR) {
	if((jqXHR.status === 401 && jqXHR.responseJSON?.errorConstant === 'INVALID_OR_EXPIRED_TOKEN') 
		|| jqXHR.responseJSON?.errorConstant === 'NOT_ENOUGH_PERMISSIONS') {
		localStorage.removeItem(LOCAL_STORAGE_TOKEN);
		console.log('Token invalid, force logging out');
		logout();
	}
});

$.ajaxSetup({
	beforeSend: function(jqXHR, settings) {
		const accessToken = getLoginDetails()?.accessToken;
			if(accessToken?.length) {
				jqXHR.setRequestHeader('JwtToken', `Bearer ${accessToken}`);
				settings.xhrFields = {
					withCredentials: true
			   };
			}
		}
});

export async function login(email, password) {
	if(!typeof email === 'string' || !email.length
		|| !typeof password === 'string' || !password.length) {
		throw new Error('Credentials must be set');
	}
	await new Promise((resolve, reject) => {
		$.ajax({
			headers: {
				'Authorization': 'Basic OTk3N2NmZmUtYjNlNC00YmI0LTg2YTQtOTAwZjRhYTgzMDU2Og=='
			},
			method: 'POST',
			mimeType: 'application/json',
			success: function(data, textStatus, jqXHR) {
				if(setLoginDetails(jqXHR, email)) {
					resolve();
				} else {
					reject(data); //error
				}
			},
			timeout: 30000,
			url: `${apiBaseURL}/login/token`,
			error: function(jqXHR, textStatus, errorThrown) {
				if(jqXHR.status === 400 && jqXHR.responseJSON?.error === 'invalid_grant') {
					reject('WRONG_CREDENTIALS');
				} else {
					reject('OTHER_REASON');
				}
			},
			data: `grant_type=password&username=${email}&password=${password}`,
			xhrFields: {
				withCredentials: true
		   }
		});
	});
}

export function isLoggedIn() {
	return !!getLoginDetails();
}

/**
 * @returns {LoginDetails | undefined}
 */
export function getLoginDetails() {
	try {
		const json = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN)) || undefined;
		return (typeof json === 'object')? LoginDetails.fromJson(json) : undefined;
	} catch {
		return undefined;
	}
}

/**
 * 
 * @param {JQuery.jqXHR<any> | object} jqXHR 
 * @param {string} email 
 * @returns {boolean}
 */
export function setLoginDetails(jqXHR, email) {
	if(jqXHR?.status == 200 //is typeof jqXHR<OAuth>
		&& typeof jqXHR?.responseJSON?.access_token === 'string' 
		&& typeof jqXHR?.responseJSON?.userName === 'string'
		&& typeof jqXHR?.responseJSON?.userId === 'string') {
		localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify({
			accessToken: jqXHR.responseJSON.access_token, 
			userName: jqXHR.responseJSON.userName,
			userId: jqXHR.responseJSON.userId,
			userEmail: email
		}));
		return true;
	} else if(typeof jqXHR === 'object' //is javascript response object
		&& typeof jqXHR?.access_token === 'string' 
		&& typeof jqXHR?.userName === 'string'
		&& typeof jqXHR?.userId === 'string') {
		localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify({
			accessToken: jqXHR.access_token, 
			userName: jqXHR.userName,
			userId: jqXHR.userId,
			userEmail: email
		}));
		return true;
	} else {
		return false;
	}
}

export async function logout() {
	localStorage.removeItem(LOCAL_STORAGE_TOKEN);
	redirectToLoginPage();
}

export function redirectToLoginPage() {
	location.replace(`/${location.pathname.split('/')[1]}/login.html`);
}

export class LoginDetails {

	/**@type {string} */
	userName;

	/**@type {string} */
	userId;

	/**@type {string} */
	userEmail;

	/**@type {string} */
	accessToken;

	static fromJson(json) {
		const loginDetails = new LoginDetails();
		loginDetails.userName = json.userName;
		loginDetails.userId = json.userId;
		loginDetails.userEmail = json.userEmail;
		loginDetails.accessToken = json.accessToken;

		return loginDetails;
	}

	/**
	 * @returns {{firstName: string | undefined, lastName: string | undefined}}
	 */
	tryGetFirstAndLastName() {
		const split = this.userName?.split(' ').filter(x => x.length);
		return {
			firstName: split?.[0],
			lastName: split?.slice(1)?.join(' ')
		};
	}
}