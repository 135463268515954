import "../../components/header/header.js";
import "../../components/footer/footer.js";
import "../../views/pricing-view/pricing-view.js";
import "../../components/scrolltopbtn/scrolltopbtn.js";
import {waitUntilComponentLoad} from "../../shared/js/waitable-component.js";

const $tabPictures = $('[data-tab].tabs_div');
const $tabs = $('[data-tab][role="tab"]');
$tabs.on('click', function() {
    $tabs.removeClass('resp-tab-active');
    const dataTab = $(this).attr('data-tab');
    $tabs.filter((id, x) => x.getAttribute('data-tab') === dataTab).addClass('resp-tab-active');

    const $hide = $tabPictures.filter((id, x) => x.getAttribute('data-tab') !== dataTab);
    const $show = $tabPictures.filter((id, x) => x.getAttribute('data-tab') === dataTab);

    $hide.animate({
        maxHeight: 0,
        opacity: 0
    }, 333, 'linear', () => {
        $hide.hide();
        ;
    });
    $show.show().animate({maxHeight: 457, opacity: 1}, 333, 'linear');
});

const jarallaxIntersectionObserver = new IntersectionObserver((x) => {
    for(const el of x) {
        if(el.isIntersecting) {
            jarallaxIntersectionObserver.unobserve(el.target);
            jarallaxIntersectionObserver.disconnect();
            import("../../../public/frontend/node_modules/jarallax/jarallax.min.js")
                .then(_ => {
                    var $pageSection = $(".parallax,.bg-img");
                    $pageSection.each(function(indx) {
                        if ($(this).attr("data-background")) {
                            $(this).css("background-image", "url(" + $(this).data("background") + ")");
                        }
                    });
                });
        }
    }
});

jarallaxIntersectionObserver.observe($('#progress').get(0));
jarallaxIntersectionObserver.observe($('#bottom-cta').get(0));

waitUntilComponentLoad(() => {
    const $preloader = $('#preloader');
    $preloader.css('opacity', '0');
    setTimeout(() => $preloader.remove(), 500);
}, $('spon-header'));