import css from "./scrolltopbtn.css" assert {type: 'css'};

class ScrollTopButton extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({mode: 'open'});
        this.shadowRoot.innerHTML = '<a href="#" class="d-flex scroll-to-top hidden"><i class="fas m-auto fa-angle-up" aria-hidden="true"></i></a>'
        if(css instanceof CSSStyleSheet) {
            this.shadowRoot.adoptedStyleSheets = [css];
        } else if(typeof css === 'function') {
            this.shadowRoot.adoptedStyleSheets = [css()];
        }
    }

    connectedCallback() {
        const $scrollToTop = $(this.shadowRoot).find(".scroll-to-top");
        $scrollToTop.on('click', (event) => {
            event.preventDefault();
            scrollTo({top: 0, behavior: 'smooth'});
        });

        if(this.getAttribute('data-target')) {
            const intersectionObserver = new IntersectionObserver((x) => {
                if(x[0].isIntersecting) {
                    $scrollToTop.addClass('hidden');
                } else {
                    $scrollToTop.removeClass('hidden');
                }
            });
            
            const $target = $(document.body).find(this.getAttribute('data-target'));
            if($target.length) {
                $target.each((id, x) => intersectionObserver.observe(x));
            }
        }
    }
}

customElements.define('scroll-top-button', ScrollTopButton);