import * as login from "./loginhelper.js";

const ACTIVE_DOCUMENT_STORAGE_KEY = 'active-document-storage';

/**
 * 
 * @returns {Promise<ActiveDocumentDetails | null>}
 */
export async function getActiveDocumentForCurrentUser() {
    if(!login.isLoggedIn()) {
        return null;
    }

    const loggedInUserId = login.getLoginDetails().userId;
    const storage = localStorage.getItem(ACTIVE_DOCUMENT_STORAGE_KEY);

    const {db, cvFileStore, hasCVFileStore} = await openCVFileStore(loggedInUserId);
    try {
        if(!hasCVFileStore) {
            return null; //no cvFiles store -> return null
        }

        if(!storage) {
            return await setFirstDocumentOfUserAsActive(loggedInUserId, db, cvFileStore);
        } else {
            const {documentId, documentName, userId} = JSON.parse(storage);
    
            if(loggedInUserId !== userId) {
                console.warn('Mismatch between logged in userId and activeDocument userId. Refreshing');
                return await setFirstDocumentOfUserAsActive(loggedInUserId, db, cvFileStore);
            } else if(typeof documentId !== 'number' || typeof documentName !== 'string') {
                console.warn('Invalid contents of active document storage cache. Healing');
                return await setFirstDocumentOfUserAsActive(loggedInUserId, db, cvFileStore);
            } else if(!(await cvFileStoreContainsId(documentId, cvFileStore))) {
                console.warn(`Cv filestore does not contain id ${documentId}. Healing`);
                return await setFirstDocumentOfUserAsActive(loggedInUserId, db, cvFileStore);
            }
    
            return new ActiveDocumentDetails(documentId, documentName, loggedInUserId);
        }
    } finally {
        db?.close();
    }
}

/**
 * @param {number} id 
 * @returns {Promise<ActiveDocumentDetails>}
 */
export async function setActiveDocumentForCurrentUser(id) {
    if(!login.isLoggedIn()) {
        return null;
    }

    const loggedInUserId = login.getLoginDetails().userId;

    const {db, cvFileStore, hasCVFileStore} = await openCVFileStore(loggedInUserId);
    try {
        if(!hasCVFileStore) {
            return; //store should get created in dexie
        }

        const storeRequest = cvFileStore.get(id);
        const cvFile = await new Promise((success, error) => storeRequest.onsuccess = () => success(storeRequest.result));

        if(!cvFile) {
            return;
        }

        const newActiveDocument = new ActiveDocumentDetails(cvFile.id, cvFile.applicationName, loggedInUserId);
    
        localStorage.setItem(ACTIVE_DOCUMENT_STORAGE_KEY, JSON.stringify(newActiveDocument));
        return newActiveDocument;
    } finally {
        db?.close();
    }
}

/**
 * @param {string} userId 
 * @param {IDBDatabase} db
 * @param {IDBObjectStore} cvFileStore
 * @returns {Promise<ActiveDocumentDetails | null>}
 */
async function setFirstDocumentOfUserAsActive(userId, db, cvFileStore) {
    const iterator = cvFileStore.openCursor(undefined, 'prev');
    const cvFile = await new Promise((success, error) => iterator.onsuccess = () => success(iterator?.result?.value)) 
    if(cvFile) {
        const activeDocument = new ActiveDocumentDetails(cvFile.id, cvFile.applicationName, userId);
        localStorage.setItem(ACTIVE_DOCUMENT_STORAGE_KEY, JSON.stringify(activeDocument));
        return activeDocument;
    } else {
        return null;
    }
}

/**
 * @param {string} userId
 * @returns {Promise<{db: IDBDatabase, cvFileStore: IDBObjectStore | undefined, hasCVFileStore: boolean}>}
 */
async function openCVFileStore(userId) {
    /**@type {IDBDatabase} */
    let db;
    try {
        const openRequest = indexedDB.open(userId);
        db = await new Promise((success, error) => openRequest.onsuccess = () => success(openRequest.result));
        if(!db.objectStoreNames.contains('cvFiles')) {
            return {db: db, hasCVFileStore: false};
        }

        const transaction = db.transaction('cvFiles', 'readonly');
        const cvFileStore = transaction.objectStore('cvFiles');
        return {
            cvFileStore: cvFileStore,
            db: db,
            hasCVFileStore: true
        };
    } catch(err) {
        db?.close();
        throw err;
    }
}

/**
 * @param {number} id 
 * @param {IDBObjectStore} cvFileStore
 * @returns {Promise<boolean>}
 */
async function cvFileStoreContainsId(id, cvFileStore) {
    const request = cvFileStore.get(id);
    return !!(await new Promise((success, error) => request.onsuccess = () => success(request.result)));
}

export class ActiveDocumentDetails {

    /**
     * @param {number} documentId 
     * @param {string} documentName 
     * @param {string} userId 
     */
    constructor(documentId, documentName, userId) {
        /**@type {number} */
        this.documentId = documentId;

        /**@type {string} */
        this.documentName = documentName;

        /**@type {string} */
        this.userId = userId;
    }
}