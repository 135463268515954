import css from "./footer.css" assert {type: 'css'};
import {AwaitableHTMLElement, connectedCallbackAwaitable} from "../../shared/js/waitable-component.js";

const html = fetch(`/content/components/footer/${location.pathname.split('/')[1]}/footer.html`).then(x => x.text());
const logoUrl = new URL('../../../public/logo/cvcollage.svg', import.meta.url);

export class SponJobsFooter extends AwaitableHTMLElement {
    #shadowDom;
    #loadingPromise;
    constructor() {
        super();
        this.#shadowDom = this.attachShadow({mode: 'open'});

        this.#loadingPromise = html.then(x => {
            if(css instanceof CSSStyleSheet) {
                this.#shadowDom.adoptedStyleSheets = [css];
            } else if(typeof css === 'function') {
                this.#shadowDom.adoptedStyleSheets = [css()];
            }
    
            this.#shadowDom.innerHTML = x;
        });
    }

    async connectedCallback() {
        await this.#loadingPromise;
        $(this.#shadowDom).find('img').attr('src', logoUrl);
        $(this.#shadowDom).find('[data-contact]').on('click', function(event) {
            event.preventDefault();
            switch($(this).attr('data-contact')) {
                case 'support':
                    import("../brevochat/brevochat.js").then(support => support.openBrevoChat());
                break;
            }
        });
    }
}

connectedCallbackAwaitable(SponJobsFooter);
customElements.define('spon-footer', SponJobsFooter);