export default function createCss() {
        const style = new CSSStyleSheet();
        style.replaceSync(`.price-block .badge{position:absolute;top:0;right:1rem;border-radius:17px;transform:translateY(-50%);}
.price-block .badge span{vertical-align:middle;}
button.butn[disabled],button.butn[disabled]:hover,button.butn[disabled]:focus{background-color:rgb(181 182 205 / 15%);color:#afb0c9;cursor:auto;pointer-events:none;}
.badge.style-one{background-color:#292dc2;color:#fff;}

body {
    font-family: 'Poppins', sans-serif;
}

.ti::before {
    content: '';
    display: inline-block;
    background-size: 100% 100%;
    width: 17px;
    height: 17px;
}

.ti-check::before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generated by IcoMoon.io --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M15.418 1.774l-8.833 13.485-4.918-4.386 0.666-0.746 4.051 3.614 8.198-12.515 0.836 0.548z' fill='%23000000' /%3E%3C/svg%3E%0A");
}

.ti-close::before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generated by IcoMoon.io --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M9.207 8.5l6.646 6.646-0.707 0.707-6.646-6.646-6.646 6.646-0.707-0.707 6.646-6.646-6.647-6.646 0.707-0.707 6.647 6.646 6.646-6.646 0.707 0.707-6.646 6.646z' fill='%23000000' /%3E%3C/svg%3E%0A");
}

.price-block ul li i.ti {
    margin-bottom: auto;
    display: inline-flex;
}

ul[data-time="monthly"] > li.access-time-days,
ul[data-time="daily"] > li.access-time-month {
    display: none;
}

.spinner-border {
    display: none;
}

button[disabled] .spinner-border {
    display: inline-block;
}

.text-danger.ti::before, .text-danger .ti::before {
    filter: invert(24%) sepia(54%) saturate(5231%) hue-rotate(342deg) brightness(96%) contrast(79%);
}

.text-success.ti::before, .text-success .ti::before {
    filter: invert(32%) sepia(55%) saturate(5687%) hue-rotate(144deg) brightness(89%) contrast(80%);
}`);
        return style;
    }