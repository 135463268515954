/**
 * 
 * @description Function to prevent fouc
 * @param {Function} callback The callback to execute after loading completed
 * @returns 
 */
export async function waitUntilComponentLoad(callback) {
    if(arguments.length <= 1) {
        return;
    }

    const promises = [...arguments].slice(1).map(x => {
        let htmlElement;
        if(x instanceof HTMLElement) {
            htmlElement = x;
        } else if(x instanceof jQuery) {
            htmlElement = x.get(0);
        }
        
        return htmlElement?.waitForConnectedCallbackCompleted?.();
    }).filter(x => x instanceof Promise);

    await Promise.all(promises);
    callback?.();
}

export class AwaitableHTMLElement extends HTMLElement {
    constructor() {
        super();
        this.dependentComponents = [];
        this.connectedCallbackCompleted = false;
        this.style.opacity = '0';
        this.style.transition = 'opacity .333s ease';
    }

    async waitForConnectedCallbackCompleted() {
        await new Promise((success, error) => {
            if(this.connectedCallbackCompleted) {
                success();
                return;
            }

            const interval = setInterval(() => {
                if(this.connectedCallbackCompleted) {
                    clearInterval(interval);
                    success();
                }
            }, 100);
        });
    }
}

/**
 * @param {CustomElementConstructor} element 
 */
export function connectedCallbackAwaitable(element) {
    const connectedCallback = element.prototype.connectedCallback;
    element.prototype.connectedCallback = async function() {
        await connectedCallback.call(this);

        if(this.shadowRoot instanceof ShadowRoot) {
            Promise.all($(this.shadowRoot).find('link').toArray().map(x => new Promise((success, error) => $(x).ready(() => success()))))
                .then(_ => Promise.all(this.dependentComponents.map(x => x?.waitForConnectedCallbackCompleted?.()).filter(x => x instanceof Promise)))
                .then(_ => setOpacityOfAwaitableComponentTo1AndClearInlineStyles(this));
        } else {
            setOpacityOfAwaitableComponentTo1AndClearInlineStyles(this)
        }
    };
}

/**
 * @param {AwaitableHTMLElement} element 
 */
function setOpacityOfAwaitableComponentTo1AndClearInlineStyles(element) {
    element.connectedCallbackCompleted = true;
    element.style.opacity = '1';
    setTimeout(() => {
        element.style.transition = '';
        element.style.opacity = '';
    }, 333);
}