/**
 * @typedef {import('../dao/cvfile-dao.js').CVFile} CVFile
 */

import * as config from "../../../js/configmodule.js";
import * as login from "./loginhelper.js";
import {getActiveDocumentForCurrentUser, setActiveDocumentForCurrentUser, ActiveDocumentDetails} from "./active-document.js";

let pdfJsLoaded = false;

const usageBeginTimestamp = new Date();
const MIN_INTERESTED_USER_GENERATIONS_COUNT = 20;
const MIN_INTERESTED_USER_USAGE_TIME_MSEC = 300 * 1000;
let idempotId = 0;

/**@type {import('./dbhelper.js').DexieDAO} */
let userDao;

/**@type {Cache} */
let applicantImageCache;

/**
 * Callback for handling a code that should be executed when DOM content ready.
 *
 * @callback onDomReadyCallback
 */

/**
 * Execute code when DOM content loaded.
 * 
 * @param {onDomReadyCallback} callback - The callback that will be executed when DOM content loaded.
 */
export function onDomContentLoaded(callback) {
    if (typeof callback === 'function') {
        document.addEventListener('DOMContentLoaded', callback);
    }
}

export function injectCss(path) {
    if(! $(`link[href="${path}"]`).length) {
        $('<link />', {
            rel: 'stylesheet',
            href: path
        }).appendTo($('head'));
    }
}

export function validateEmail(email) {
    return typeof email === 'string' && !!email.length
        && /^(?:(?:[a-zA-Z0-9_'^&/+-]+(?:\.[a-zA-Z0-9_'^&/+-]+)*)|"(?:[a-zA-Z0-9_'^&/+-]+(?:\.[a-zA-Z0-9_'^&/+-]+)*)")@(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d+)?$/.test(email.toLowerCase());
}

/**
 * @param {string?} name 
 */
export function validateFirstAndLastName(name) {
    return !!name?.length && /^[\p{L} ,.'-]+$/u.test(name);
}

export function fireGAEvent(name, dimensions = null) {
    if(typeof gtag === 'function') {
        let data = {'send_to': 'G-1PRE4VJ0HY'};
        if(dimensions) {
            data = Object.assign({}, data, dimensions)
        }
        
        gtag('event', name, data);
    }
}

export function showErrorMsg(title, text, icon = 'error') {
    injectCss('https://cdn.jsdelivr.net/npm/sweetalert2@11.7.2/dist/sweetalert2.min.css');
    import(/* webpackIgnore: true */ 'https://cdn.jsdelivr.net/npm/sweetalert2@11.7.2/dist/sweetalert2.all.min.js')
        .then(_ => Sweetalert2.fire({
            titleText: title,
            text: text,
            icon: icon,
            toast: true,
            position: 'bottom-end',
            timer: 5000,
            heightAuto: false
        }));
}

export function firePreviewCreatedEvent(templateCode) {
    fireGAEvent('preview_created', {'cv_template_code': templateCode});
    idempotId++;

    if(idempotId > MIN_INTERESTED_USER_GENERATIONS_COUNT 
        && Math.abs(new Date().getTime() - usageBeginTimestamp.getTime()) > MIN_INTERESTED_USER_USAGE_TIME_MSEC
        && !localStorage.getItem('interested_user_evt_fired')) {
        fireGAEvent('interested_user');
        localStorage.setItem('interested_user_evt_fired', 'true');
    }
}

/**
 * @param {'CV' | 'COVER_LETTER'} type 
 */
export async function increaseCVPreviewsCountInIndexDb(type = 'CV') {
    if(login.isLoggedIn()) {
        const dbHelper = await import(/* webpackChunkName: "dbhelper", webpackPrefetch: false */ "./dbhelper.js");
        userDao ||= new dbHelper.DexieDAO(login.getLoginDetails().userId);

        const statisticDao = await userDao.getStatisticsDao();
        await statisticDao.registerCVPreview(type);
    }
}


/**
 * @param {import('../api/models.js').CVRequestData} cvRequestData
 * @param {string} templateCode
 * @param {string} language
 * @param {'CV' | 'COVER_LETTER'} type
 * @returns {Promise<void>}
 */
export async function saveResumeDataAfterPreview(cvRequestData, templateCode, language, type) {
    if(!login.isLoggedIn()) {
        return;
    }

    let {cvFile, applicantImageEntity} = await fillCommonDataIntoCVFile(cvRequestData, templateCode, language, type);
    const cvFileDao = await userDao.getCVFileDao();
    const applicantImageDao = await userDao.getApplicantImageDao();

    userDao.transaction('rw', [cvFileDao, applicantImageDao], async () => {
        if(applicantImageEntity) {
            await applicantImageDao.save(applicantImageEntity);
        }

        await cvFileDao.saveCV(cvFile);
    });
}

/**
 * @param {import('../api/models.js').CVRequestData} cvRequestData
 * @param {string} templateCode
 * @param {string} language
 * @param {Blob} blob
 * @param {'CV' | 'COVER_LETTER'} type
 * @returns {Promise<ActiveDocumentDetails | undefined>}
 */
export async function saveCvDataInIndexedDb(cvRequestData, templateCode, language, blob, type = 'CV') {
    if(!login.isLoggedIn()) {
        return;
    }

    let {activeDocument, cvFile, applicantImageEntity} = await fillCommonDataIntoCVFile(cvRequestData, templateCode, language, type);

    const documentsDao = await userDao.getGeneratedDocumentsDao();

    const cvFileDao = await userDao.getCVFileDao();
    const statisticDao = await userDao.getStatisticsDao();
    const applicantImageDao = await userDao.getApplicantImageDao();

    const pdfDocumentChecksum = Array.from(new Uint8Array(await crypto.subtle.digest('SHA-1', await blob.arrayBuffer())))
        .map(x => x.toString(16).padStart(2, "0")).join("");
    
    await userDao.transaction('rw', [applicantImageDao, cvFileDao, statisticDao, documentsDao], async () => {
        if(applicantImageEntity) {
            await applicantImageDao.save(applicantImageEntity);
        }

        await cvFileDao.saveCV(cvFile);
        await documentsDao.updatePdfBlob(cvFile.id, type, blob, cvFile.getDataRelevantChecksum(type), pdfDocumentChecksum);
        await statisticDao.registerCVGeneration(type);
    });

    if(!(activeDocument instanceof ActiveDocumentDetails)) {
        activeDocument = await setActiveDocumentForCurrentUser(cvFile.id);
    }

    updateGeneratedDocumentPreview(cvFile.id, type); //leave it running!
    return activeDocument;
}

/**
 * @param {import('../api/models.js').CVRequestData} cvRequestData
 * @param {string} templateCode
 * @param {string} language
 * @param {'CV' | 'COVER_LETTER'} type
 * @returns {Promise<{activeDocument: ActiveDocumentDetails | undefined, cvFile: CVFile, applicantImageEntity: import("../dao/applicant-image-dao.js").ApplicantImageEntity | undefined}>}
 */
async function fillCommonDataIntoCVFile(cvRequestData, templateCode, language, type) {
    const activeDocument = await getActiveDocumentForCurrentUser();
    const dbHelper = await import(/* webpackChunkName: "dbhelper", webpackPrefetch: false */ "./dbhelper.js");
    const cvDaoModule = await import("../dao/cvfile-dao.js");
    userDao ??= new dbHelper.DexieDAO(login.getLoginDetails().userId);
    const cvFileDao = await userDao.getCVFileDao();

    /**@type {CVFile} */
    let cvFile;
    if(activeDocument instanceof ActiveDocumentDetails) {
        cvFile = await cvFileDao.getSavedCVById(activeDocument.documentId);
    } else {
        cvFile = new cvDaoModule.CVFile();
        cvFile.applicationName = 'New CV';
    }

    cvFile.fillCVRequestData(cvRequestData);
    cvFile.pictureSelectorSettings = JSON.parse(localStorage.getItem(config.PICTURE_SELECTOR_SETTINGS_CACHE) ?? null);
    cvFile.referencesCache = JSON.parse(localStorage.getItem(config.REFERENCES_CACHE) ?? null);
    cvFile.templateCode = templateCode;
    cvFile.language = language;

    cvFile.computeDataRelevantChecksum(type);

    //setting applicant image:
    const applicantImage = await cacheStorageGetApplicantPicture().then(x => x?.arrayBuffer());
    const applicantImageDaoModule = await import("../dao/applicant-image-dao.js");
    
    let applicantImageEntity;
    if(applicantImage instanceof ArrayBuffer) {
        applicantImageEntity = await applicantImageDaoModule.ApplicantImageEntity.fromBuffer(applicantImage);
        cvFile.applicantImageChecksum = applicantImageEntity.checksum;
    } else {
        cvFile.applicantImageChecksum = null; //no applicant image set -> remove checksum
    }

    return {
        activeDocument: activeDocument,
        cvFile: cvFile,
        applicantImageEntity: applicantImageEntity
    };
}

/**
 * @param {number} cvFileId 
 * @param {'CV' | 'COVER_LETTER'} documentType 
 * @returns {Promise<Blob | undefined>}
 */
export async function updateGeneratedDocumentPreview(cvFileId, documentType) {
    if(!login.isLoggedIn()) {
        return;
    }

    const dbHelper = await import(/* webpackChunkName: "dbhelper", webpackPrefetch: false */ "./dbhelper.js");
    userDao ??= new dbHelper.DexieDAO(login.getLoginDetails().userId);

    const documentsDao = await userDao.getGeneratedDocumentsDao();
    const document = await documentsDao.getGeneratedDocumentForCVFileIdAndDocumentType(cvFileId, documentType);
    if(document && document.pdfDocument instanceof Blob) {
        const preview = (await cPTI(await document.pdfDocument.arrayBuffer(), {width: 350.0, height: 350.0 * Math.sqrt(2)}, 2.0, [0]))[0];

        document.pdfDocumentPreview = preview;
        document.pdfDocumentPreviewChecksum = document.pdfDocumentChecksum;
        await documentsDao.save(document);
        return preview;
    }
}

/**
 * @returns {Promise<Response | undefined>}
 */
export async function cacheStorageGetApplicantPicture() {
    applicantImageCache ??= await caches.open('picture');
    return await applicantImageCache.match('/picture')
}

/**
 * @param {BodyInit} file 
 */
export async function cacheStorageSetApplicantPicture(file) {
    applicantImageCache ??= await caches.open('picture');
    await applicantImageCache.put(new Request('/picture'), new Response(file))
}

export async function cacheStorageDeleteApplicantPicture() {
    applicantImageCache ??= await caches.open('picture');
    await applicantImageCache.delete('/picture');
}

/**
 * @deprecated
 */
export function getActivePromoCode() {
    let promoCodes = localStorage.getItem(COUPON_CACHE);
    if(promoCodes && (promoCodes = JSON.parse(promoCodes))
        && typeof promoCodes.promoCode === 'string'
        && (!promoCodes.expiryDate || (new Date(promoCodes.expiryDate).getTime() >= new Date().getTime()))) {
        return promoCodes;
    }
}

/**
 * @deprecated
 */
export function updatePromoCodeValidity(validityAfterFetchInSec) {
    let promoCodes = localStorage.getItem(COUPON_CACHE);
    if(typeof validityAfterFetchInSec === 'number' && validityAfterFetchInSec > 0
        && promoCodes && (promoCodes = JSON.parse(promoCodes))
        && typeof promoCodes.promoCode === 'string'
        && (!promoCodes.expiryDate || (new Date(promoCodes.expiryDate).getTime() >= new Date().getTime()))) {
        promoCodes.expiryDate ??= new Date().getTime() + validityAfterFetchInSec * 1000;
        localStorage.setItem(COUPON_CACHE, JSON.stringify(promoCodes));
    }
}

export function loadPdfJs() {
    return new Promise((resolve, reject) => {
        if(!pdfJsLoaded) {
            $.when(
                $.getScript("https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.min.js"),
                $.getScript("https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.min.js"),
                $.Deferred(function( deferred ){
                    $( deferred.resolve );
                })
            ).done(() => {
                pdfJsLoaded = true;
                resolve();
            })
            .fail(() => reject());
        } else {
            resolve();
        }
    });
}

export function cvPreview(templateCode, lang, jsonCV, idempotId = 333, type = 'CV') {
    const xhr = new XMLHttpRequest();
        
    let url;
    switch(type) {
        case 'CV':
            url = `${apiBaseURL}/resume/preview2`;
        break;
        case 'COVER_LETTER':
            url = `${apiBaseURL}/resume/coverletter`;
        break;
        default:
            throw `Preview ${type} not implemented`;
    }

    xhr.open('PUT', url);
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader('x-sponjobs-language', lang);
    xhr.setRequestHeader('x-sponjobs-templatecode', templateCode);
    xhr.setRequestHeader('x-sponjobs-data', idempotId.toString());

    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json, application/pdf'); //both json and zip allowed

    const previewPromise = new Promise((resolve, reject) => {
        xhr.onload = async function() {
            if(this.status >= 200 && this.status < 300) {
                resolve(xhr);
            } else {
                reject({
                    status: this.status,
                    response: xhr.response
                });
            }
        };

        xhr.onerror = function() {
            reject({
                status: this.status,
                response: xhr.response
            });
        };

        xhr.send((typeof jsonCV === 'string')? jsonCV : JSON.stringify(jsonCV));
    });

    return loadPdfJs().then(x => previewPromise);
}

export async function previewPictures(xhr, rightPreview) {
    const responseIdempotenceHeader = xhr.getResponseHeader('x-sponjobs-data');
    let pictures;
    if((responseIdempotenceHeader && Number(responseIdempotenceHeader) > rightPreview.getIdempotenceId())) {
        pictures = await cPTI(xhr.response);
        await rightPreview.addPictures(pictures);
        
        rightPreview.setIdempotenceId(Number(responseIdempotenceHeader));
        rightPreview.showPictures();
    }

    return pictures;
}

export async function previewPicturesErrorHandler(rightPreview, error, templateCode) {
    const languageData = await getLanguageData();
    rightPreview.showErrorMessage(languageData["error"]["templateViewer"]["GENERIC"]);
    console.log(error);
    fireGAEvent('preview_failed', {'cv_template_code': templateCode});
    throw error;
}

/**
 * @param {ArrayBuffer} p 
 * @param {*} $container 
 * @param {JQueryStatic | HTMLElement | {width: number, height: number}} size 
 * @param {number} targetScale 
 * @param {number[] | null} pageNumbers
 * @returns {Promise<Blob[]>}
 */
export async function cPTI(p, size = null, targetScale = 2.0, pageNumbers) {
    await loadPdfJs();
    var outputScale = window.devicePixelRatio || 1;
    const doc = await pdfjsLib.getDocument(new Uint8Array(p)).promise;
    const numPages = doc.numPages;
    let pages = new Array(numPages).fill(undefined);

    let width;
    let height;
    if(size instanceof HTMLElement) {
        const rect = size.getBoundingClientRect();
        width = rect.width;
        height = rect.height;
    } else if(size instanceof jQuery) {
        width = size.width();
        height = size.height();
    } else if(typeof size?.width === 'number' && typeof size?.height === 'number') {
        width = size.width;
        height = size.height;
    } else {
        width = screen.width;
        height = screen.height;
    }

    for(let i = 0; i < numPages; i++) {
        if(pageNumbers instanceof Array && pageNumbers.indexOf(i) === -1) {
            pages[i] = null;
            continue;
        }

        await doc.getPage(i + 1).then(async function(page) {
            var unscaledViewport = page.getViewport({scale: 1.0});
            var scale = targetScale * Math.min((height / unscaledViewport.height), (width / unscaledViewport.width)); //we want to be able to zoom 2 times
            scale = Math.min(scale, 3.0); //scale should not be bigger than 3

            var viewport = page.getViewport({ scale: scale });
            var c = document.createElement("canvas"),  // create a temp. canvas
                ctx = c.getContext("2d");

            console.log(scale);

            c.width = Math.floor(viewport.width * outputScale);
            c.height = Math.floor(viewport.height * outputScale);

            var transform = (outputScale !== 1) ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            var renderContext = {
            canvasContext: ctx,
            transform: transform,
            viewport: viewport
            };

            await page.render(renderContext).promise;
            await new Promise((success, error) => {
                c.toBlob((blob) => {
                    pages[i] = blob;
                    success()
                }, "image/jpeg", 1.0);
            });
        });
    }

    return pages;
}

/**
 * @param {string} firstName 
 * @param {string} lastName 
 * @returns {{initials: string, initialBgcColor: string, initialTxtColor: string}}
 */
export function calculateInitialsData(firstName, lastName) {
    const initials = [firstName, lastName].filter(x => !!x && x.length).map(x => x.charAt(0).toUpperCase()).join('');
    let initialBgcColor;
    let initialTxtColor;
    switch(((initials.charCodeAt(0) << 16) | (initials.charCodeAt(1))) % 7) {
        case 0:
            initialBgcColor = '#d2e6f6';
            initialTxtColor = '#2971bf';
        break;
        case 1:
            initialBgcColor = '#daeeda';
            initialTxtColor = '#508335';
        break;
        case 2:
            initialBgcColor = '#dddbed';
            initialTxtColor = '#766cba';
        break;
        case 3:
            initialBgcColor = '#f7d7d1';
            initialTxtColor = '#d52727';
        break;
        case 4:
            initialBgcColor = '#f5d3e0';
            initialTxtColor = '#cf3074';
        break;
        case 5:
            initialBgcColor = '#f0e2d8';
            initialTxtColor = '#b95a3c';
        break;
        default:
            initialBgcColor = '#292dc2';
            initialTxtColor = '#ffffff';
        break;
    }

    return {
        initials: initials,
        initialBgcColor: initialBgcColor,
        initialTxtColor: initialTxtColor
    };
}

export function computeJsonChecksum(jsonCV) {
    const cyrb53 = function(str, seed = 0) {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
        h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1>>>0);
    };

    const stringifyForChecksumComputation = function(jsonCV) {
        if (Array.isArray(jsonCV)) {
          return JSON.stringify(jsonCV.map(i => stringifyForChecksumComputation(i)))
        } else if (typeof jsonCV === 'object' && jsonCV !== null) {
          return Object.keys(jsonCV)
            .sort()
            .map(k => `${k}:${stringifyForChecksumComputation(jsonCV[k])}`)
            .join('|')
        }
      
        return jsonCV;
    };

    const json = stringifyForChecksumComputation(jsonCV);
    return cyrb53(json);
}

/**
 * @deprecated
 */
export function downloadFileFromDataUrl(dataUrl, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUrl;
    downloadLink.target = "_blank";
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
}

/**
 * 
 * @param {Blob} blob 
 * @param {string} fileName 
 */
export function downloadBlobFile(blob, fileName) {
    const url = URL.createObjectURL(blob);
    try {
        downloadFileFromDataUrl(url, fileName);
    } finally {
        if(typeof url === 'string' && url.length) {
            URL.revokeObjectURL(url);
        }
    }
}

export function getLangaugeCodeFromQueryParameters() {
    switch(location.pathname.split('/')[1]) {
        case 'en':
            return 'ENGLISH';
        case 'ru':
            return 'RUSSIAN';
        default:
            return 'GERMAN';
    }
}

let languageData;
export async function getLanguageData() {
    languageData ??= (await (await fetch(`/content/shared/data/cv_data/data-${location.pathname.split('/')[1]}.json`)).json());
    return languageData;
}

export async function fillMissingDataInCacheWithDefaultLanguageData(fillCVData, fillCoverLetterData, fillSignatureData) {
    const config = await import("../../../js/configmodule.js");
    if((!!fillCVData && !localStorage.getItem(config.CV_DATA_LOCAL_CACHE))
        || (!!fillCoverLetterData && !localStorage.getItem(config.COVERLETTER_DATA_LOCAL_CACHE))
        || (!!fillSignatureData && !localStorage.getItem(config.SIGNATURE_CACHE))) {
        const langData = await getLanguageData();

        if(!!fillCVData && !localStorage.getItem(config.CV_DATA_LOCAL_CACHE)) {
            localStorage.setItem(config.CV_DATA_LOCAL_CACHE, JSON.stringify(langData.exampleData));
        }

        if(!!fillCoverLetterData && !localStorage.getItem(config.COVERLETTER_DATA_LOCAL_CACHE)) {
            localStorage.setItem(config.COVERLETTER_DATA_LOCAL_CACHE, JSON.stringify(langData.coverLetterData))
        }

        if(!!fillSignatureData && !localStorage.getItem(config.SIGNATURE_CACHE)) {
            localStorage.setItem(config.SIGNATURE_CACHE, JSON.stringify(langData.signature));
        }
    }
}

export function shadowModal($modal, mode) {
    switch(mode) {
        case 'show':
        case 'open':
            if($modal.data('backdrop')) {
                return;
            }
        
            const $backdrop = $('<div />', {class: 'modal-backdrop fade'});
            $modal.after($backdrop);
            $modal.one('mousedown', (event) => shadowModal($modal, 'hide'));
            $backdrop.addClass('show');
            setTimeout(() => {
                $modal.data('backdrop', $backdrop).show();
                setTimeout(() => $modal.addClass('show'), 150);
            }, 150);

            $('body').addClass('modal-open');
        break;
        case 'hide':
        case 'close':
            if(! $modal.data('backdrop')) {
                return;
            }

            $modal.removeClass('show');
            setTimeout(() => {
                $modal.hide();
                $modal.data('backdrop').removeClass('show');
                setTimeout(() => {
                    $modal.data('backdrop')?.remove();
                    $modal.removeData('backdrop');
                }, 150);
            }, 150);

            $('body').removeClass('modal-open');
        break;
    }
}

let _staticFontsSvg;
export function prefetchStaticFontsSVG() {
    console.log('Prefetching static fonts svg');
    _staticFontsSvg ??= prefetchStaticFontsSVGAsync();
}

export async function getStaticFontsSVG() {
    _staticFontsSvg ??= prefetchStaticFontsSVGAsync();
    return await _staticFontsSvg;
}

async function prefetchStaticFontsSVGAsync() {
    const response = await fetch(`${config.STATIC_ASSET_URL}/fonts/fonts.svg`,{cache: "no-cache"});
    const svg = await response.text();
    const $svg = $(svg);
    $svg.addClass('d-none'); //display: none!
    $svg.appendTo($('body'));
    return $svg;
}

window.sjHelpers = (function () {
    return {
        trackFbqEvent: function(name, dimensions = null) {
            if(typeof fbq === 'function') {
                if(! dimensions) {
                    fbq('track', name); //for facebook tracking
                } else {
                    fbq('track', name, dimensions); //for facebook tracking
                }
            }
        },
        getType: function (e, t) {
        return 'number' === e ? !isNaN(parseFloat(t)) && isFinite(t) : Object.prototype.toString.call(t).slice(8, - 1).toLowerCase() === e.toLowerCase()
        },
        isObject: function (e) {
        return this.getType('object', e)
        },
        isArray: function (e) {
        return this.getType('array', e)
        },
        isString: function (e) {
        return this.getType('string', e)
        },
        isNumeric: function (e) {
        return this.getType('number', e)
        },
        isUndefined: function (e) {
        return this.getType('undefined', e)
        },
        isBoolean: function (e) {
        return this.getType('boolean', e)
        },
        isFunction: function (e) {
        return this.getType('function', e)
        }
    }
})();

export class References {
    static #DO_NOT_SHOW = 'DO_NOT_SHOW';
    static #ON_DEMAND = 'ON_DEMAND';
    static #SHOW = 'SHOW';

    constructor(mode, references) {
        if(mode !== References.#DO_NOT_SHOW && mode !== References.#ON_DEMAND && mode !== References.#SHOW) {
            throw `${mode} is an invalid referencemode`;
        }

        this.mode = mode;
        this.references = references;
    }

    static loadFromLocalCache() {
        const cache = JSON.parse(localStorage.getItem(config.REFERENCES_CACHE) ?? null);
        if(!cache) {
            return null;
        }

        try {
            return new References(cache.mode, cache.references);
        } catch {
            return null;
        }
    }

    getCVBuilderRequest() {
        switch(this.mode) {
            case References.#DO_NOT_SHOW:
                return null;
            case References.#ON_DEMAND:
                return {areAvailable: false};
            case References.#SHOW:
                return {areAvailable: true, references: (this.references?.length)? this.references : undefined}
            default:
                throw `${this.mode} is an invalid referencemode`;
        }
    }

    saveToLocalCache() {
        localStorage.setItem(config.REFERENCES_CACHE, JSON.stringify({mode: this.mode, references: this.references}));
    }
}