export class UpgradeRequest {
    /**@type {UpgradePlanDetails} */
    upgradeDetails;

    /**@type {string} */
    userEmail;

    /**@type {string} */
    firstName;

    /**@type {string} */
    lastName;
}

export class UpgradePlanResponse {

    /**@type {string} */
    generatedObject;

    /**@type {string} */
    activationId;

    /**@type {string} */
    paymentProviderData;

    /**@type {string} */
    paymentProvider;

    /**@type {number} */
    paymentAmount;

    /**@type {string} */
    paymentCurrency;

    static fromJson(json) {
        const response = new UpgradePlanResponse();
        response.generatedObject = json.generatedObject;
        response.activationId = json.activationId;
        response.paymentProviderData = json.paymentProviderData;
        response.paymentProvider = json.paymentProvider;
        response.paymentAmount = json.paymentAmount;
        response.paymentCurrency = json.paymentCurrency;

        return response;
    }
}

export class UpgradePlanDetails {

    /**@type {string} */
    planCode;

    /**@type {string} */
    preferredCurrency;

    /**@type {string} */
    locale;

    constructor(planCode, preferredCurrency, locale) {
        this.planCode = planCode;
        this.preferredCurrency = preferredCurrency;
        this.locale = locale;
    }
}

export class PricingAndPlanDetails {
    /**@type {boolean} */
    allowedToUpgrade;

    /**@type {number} */
    planDurationInDays;

    /**@type {boolean} */
    coverLettersAllowed;

    /**@type {PlanPricing[]} */
    prices;

    /**@type {number} */
    aiCredits;

    /**
     * @param {string} currency 
     * @returns {PlanPricing}
     */
    getPriceForCurrency(currency) {
        return this.prices.find(x => x.priceCurrency === currency) 
            || this.prices.find(x => x.priceCurrency == 'USD')
            || this.prices[0];
    }

    static fromJson(json) {
        const details = new PricingAndPlanDetails();
        details.allowedToUpgrade = json.allowedToUpgrade;
        details.planDurationInDays = json.planDurationInDays;
        details.coverLettersAllowed = json.coverLettersAllowed;
        details.aiCredits = json.aiCredits;
        if(json.prices instanceof Array) {
            details.prices = json.prices.map(x => PlanPricing.fromJson(x));
        }

        return details;
    }
}

export class PlanPricing {
    /**@type {number} */
    upgradePrice;

    /**@type {number} */
    discountPrice;

    /**@type {string} */
    priceCurrency;

    /**
     * @param {object} json 
     * @returns {PlanPricing}
     */
    static fromJson(json) {
        const pricing = new PlanPricing();
        pricing.discountPrice = json.discountPrice;
        pricing.priceCurrency = json.priceCurrency;
        pricing.upgradePrice = json.upgradePrice;
        return pricing;
    }
}

export class CVRequestData {
    /**@type {string} */
    applicantImage;

    /**@type {object} */
    customCaptions;

    /**@type {object} */
    resumeData;

    /**@type {object} */
    coverLetterData;

    /**@type {object} */
    style;

    /**@type {object} */
    metadata;

    /**@type {object} */
    signature;

    /**
     * 
     * @param {string | object} json 
     * @returns {CVRequestData}
     */
    static fromJson(json) {
        if(typeof json === 'string') {
            return CVRequestData.fromJson(JSON.parse(json));
        }

        const data = new CVRequestData();
        data.applicantImage = json.applicantImage;
        data.customCaptions = json.customCaptions;
        data.resumeData = json.resumeData;
        data.coverLetterData = json.coverLetterData;
        data.style = json.style;
        data.metadata = json.metadata;
        data.signature = json.signature;
        
        return data;
    }
}