const COUNTRIES = {
    "Andorra": undefined,
    "Dubai": undefined,
    "Kabul": undefined,
    "Tirane": undefined,
    "Yerevan": undefined,
    "Casey": undefined,
    "Davis": undefined,
    "Mawson": undefined,
    "Palmer": undefined,
    "Rothera": undefined,
    "Troll": undefined,
    "Vostok": undefined,
    "Buenos_Aires": undefined,
    "Cordoba": undefined,
    "Salta": undefined,
    "Jujuy": undefined,
    "Tucuman": undefined,
    "Catamarca": undefined,
    "La_Rioja": undefined,
    "San_Juan": undefined,
    "Mendoza": undefined,
    "San_Luis": undefined,
    "Rio_Gallegos": undefined,
    "Ushuaia": undefined,
    "Pago_Pago": undefined,
    "Vienna": "EUR",
    "Lord_Howe": undefined,
    "Macquarie": undefined,
    "Hobart": undefined,
    "Melbourne": undefined,
    "Sydney": undefined,
    "Broken_Hill": undefined,
    "Brisbane": undefined,
    "Lindeman": undefined,
    "Adelaide": undefined,
    "Darwin": undefined,
    "Perth": undefined,
    "Eucla": undefined,
    "Baku": undefined,
    "Barbados": undefined,
    "Dhaka": undefined,
    "Brussels": undefined,
    "Sofia": undefined,
    "Bermuda": undefined,
    "Brunei": undefined,
    "La_Paz": undefined,
    "Noronha": undefined,
    "Belem": undefined,
    "Fortaleza": undefined,
    "Recife": undefined,
    "Araguaina": undefined,
    "Maceio": undefined,
    "Bahia": undefined,
    "Sao_Paulo": undefined,
    "Campo_Grande": undefined,
    "Cuiaba": undefined,
    "Santarem": undefined,
    "Porto_Velho": undefined,
    "Boa_Vista": undefined,
    "Manaus": undefined,
    "Eirunepe": undefined,
    "Rio_Branco": undefined,
    "Thimphu": undefined,
    "Minsk": undefined,
    "Belize": undefined,
    "St_Johns": undefined,
    "Halifax": undefined,
    "Glace_Bay": undefined,
    "Moncton": undefined,
    "Goose_Bay": undefined,
    "Toronto": undefined,
    "Nipigon": undefined,
    "Thunder_Bay": undefined,
    "Iqaluit": undefined,
    "Pangnirtung": undefined,
    "Winnipeg": undefined,
    "Rainy_River": undefined,
    "Resolute": undefined,
    "Rankin_Inlet": undefined,
    "Regina": undefined,
    "Swift_Current": undefined,
    "Edmonton": undefined,
    "Cambridge_Bay": undefined,
    "Yellowknife": undefined,
    "Inuvik": undefined,
    "Dawson_Creek": undefined,
    "Fort_Nelson": undefined,
    "Whitehorse": undefined,
    "Dawson": undefined,
    "Vancouver": undefined,
    "Cocos": undefined,
    "Zurich": undefined,
    "Abidjan": undefined,
    "Rarotonga": undefined,
    "Santiago": undefined,
    "Punta_Arenas": undefined,
    "Easter": undefined,
    "Shanghai": undefined,
    "Urumqi": undefined,
    "Bogota": undefined,
    "Costa_Rica": undefined,
    "Havana": undefined,
    "Cape_Verde": undefined,
    "Christmas": undefined,
    "Nicosia": undefined,
    "Famagusta": undefined,
    "Prague": undefined,
    "Berlin": "EUR",
    "Copenhagen": undefined,
    "Santo_Domingo": undefined,
    "Algiers": undefined,
    "Guayaquil": undefined,
    "Galapagos": undefined,
    "Tallinn": undefined,
    "Cairo": undefined,
    "El_Aaiun": undefined,
    "Madrid": "EUR",
    "Ceuta": "EUR",
    "Canary": "EUR",
    "Helsinki": undefined,
    "Fiji": undefined,
    "Stanley": undefined,
    "Chuuk": undefined,
    "Pohnpei": undefined,
    "Kosrae": undefined,
    "Faroe": undefined,
    "Paris": "EUR",
    "London": undefined,
    "Tbilisi": undefined,
    "Cayenne": undefined,
    "Gibraltar": undefined,
    "Nuuk": undefined,
    "Danmarkshavn": undefined,
    "Scoresbysund": undefined,
    "Thule": undefined,
    "Athens": undefined,
    "South_Georgia": undefined,
    "Guatemala": undefined,
    "Guam": undefined,
    "Bissau": undefined,
    "Guyana": undefined,
    "Hong_Kong": undefined,
    "Tegucigalpa": undefined,
    "Port-au-Prince": undefined,
    "Budapest": undefined,
    "Jakarta": undefined,
    "Pontianak": undefined,
    "Makassar": undefined,
    "Jayapura": undefined,
    "Dublin": undefined,
    "Jerusalem": undefined,
    "Kolkata": undefined,
    "Chagos": undefined,
    "Baghdad": undefined,
    "Tehran": undefined,
    "Reykjavik": undefined,
    "Rome": undefined,
    "Jamaica": undefined,
    "Amman": undefined,
    "Tokyo": undefined,
    "Nairobi": undefined,
    "Bishkek": undefined,
    "Tarawa": undefined,
    "Kanton": undefined,
    "Kiritimati": undefined,
    "Pyongyang": undefined,
    "Seoul": undefined,
    "Almaty": undefined,
    "Qyzylorda": undefined,
    "Qostanay": undefined,
    "Aqtobe": undefined,
    "Aqtau": undefined,
    "Atyrau": undefined,
    "Oral": undefined,
    "Beirut": undefined,
    "Colombo": undefined,
    "Monrovia": undefined,
    "Vilnius": undefined,
    "Luxembourg": undefined,
    "Riga": undefined,
    "Tripoli": undefined,
    "Casablanca": undefined,
    "Monaco": undefined,
    "Chisinau": undefined,
    "Majuro": undefined,
    "Kwajalein": undefined,
    "Yangon": undefined,
    "Ulaanbaatar": undefined,
    "Hovd": undefined,
    "Choibalsan": undefined,
    "Macau": undefined,
    "Martinique": undefined,
    "Malta": undefined,
    "Mauritius": undefined,
    "Maldives": undefined,
    "Mexico_City": undefined,
    "Cancun": undefined,
    "Merida": undefined,
    "Monterrey": undefined,
    "Matamoros": undefined,
    "Mazatlan": undefined,
    "Chihuahua": undefined,
    "Ojinaga": undefined,
    "Hermosillo": undefined,
    "Tijuana": undefined,
    "Bahia_Banderas": undefined,
    "Kuala_Lumpur": undefined,
    "Kuching": undefined,
    "Maputo": undefined,
    "Windhoek": undefined,
    "Noumea": undefined,
    "Norfolk": undefined,
    "Lagos": undefined,
    "Managua": undefined,
    "Amsterdam": undefined,
    "Oslo": undefined,
    "Kathmandu": undefined,
    "Nauru": undefined,
    "Niue": undefined,
    "Auckland": undefined,
    "Chatham": undefined,
    "Panama": undefined,
    "Lima": undefined,
    "Tahiti": undefined,
    "Marquesas": undefined,
    "Gambier": undefined,
    "Port_Moresby": undefined,
    "Bougainville": undefined,
    "Manila": undefined,
    "Karachi": undefined,
    "Warsaw": undefined,
    "Miquelon": undefined,
    "Pitcairn": undefined,
    "Puerto_Rico": undefined,
    "Gaza": undefined,
    "Hebron": undefined,
    "Lisbon": undefined,
    "Madeira": undefined,
    "Azores": undefined,
    "Palau": undefined,
    "Asuncion": undefined,
    "Qatar": undefined,
    "Reunion": undefined,
    "Bucharest": undefined,
    "Belgrade": undefined,
    "Kaliningrad": "RUB",
    "Moscow": "RUB",
    "Simferopol": "RUB",
    "Kirov": "RUB",
    "Volgograd": "RUB",
    "Astrakhan": "RUB",
    "Saratov": "RUB",
    "Ulyanovsk": "RUB",
    "Samara": "RUB",
    "Yekaterinburg": "RUB",
    "Omsk": "RUB",
    "Novosibirsk": "RUB",
    "Barnaul": "RUB",
    "Tomsk": "RUB",
    "Novokuznetsk": "RUB",
    "Krasnoyarsk": "RUB",
    "Irkutsk": "RUB",
    "Chita": "RUB",
    "Yakutsk": "RUB",
    "Khandyga": "RUB",
    "Vladivostok": "RUB",
    "Ust-Nera": "RUB",
    "Magadan": "RUB",
    "Sakhalin": "RUB",
    "Srednekolymsk": "RUB",
    "Kamchatka": "RUB",
    "Anadyr": "RUB",
    "Riyadh": undefined,
    "Guadalcanal": undefined,
    "Mahe": undefined,
    "Khartoum": undefined,
    "Stockholm": undefined,
    "Singapore": undefined,
    "Paramaribo": undefined,
    "Juba": undefined,
    "Sao_Tome": undefined,
    "El_Salvador": undefined,
    "Damascus": undefined,
    "Grand_Turk": undefined,
    "Ndjamena": undefined,
    "Kerguelen": undefined,
    "Bangkok": undefined,
    "Dushanbe": undefined,
    "Fakaofo": undefined,
    "Dili": undefined,
    "Ashgabat": undefined,
    "Tunis": undefined,
    "Tongatapu": undefined,
    "Istanbul": undefined,
    "Funafuti": undefined,
    "Taipei": undefined,
    "Kiev": undefined,
    "Uzhgorod": undefined,
    "Zaporozhye": undefined,
    "Wake": undefined,
    "New_York": "USD",
    "Detroit": "USD",
    "Louisville": "USD",
    "Monticello": "USD",
    "Indianapolis": "USD",
    "Vincennes": "USD",
    "Winamac": "USD",
    "Marengo": "USD",
    "Petersburg": "USD",
    "Vevay": "USD",
    "Chicago": "USD",
    "Tell_City": "USD",
    "Knox": "USD",
    "Menominee": "USD",
    "Center": "USD",
    "New_Salem": "USD",
    "Beulah": "USD",
    "Denver": "USD",
    "Boise": "USD",
    "Phoenix": "USD",
    "Los_Angeles": "USD",
    "Anchorage": "USD",
    "Juneau": "USD",
    "Sitka": "USD",
    "Metlakatla": "USD",
    "Yakutat": "USD",
    "Nome": "USD",
    "Adak": "USD",
    "Honolulu": "USD",
    "Montevideo": undefined,
    "Samarkand": undefined,
    "Tashkent": undefined,
    "Caracas": undefined,
    "Ho_Chi_Minh": undefined,
    "Efate": undefined,
    "Wallis": undefined,
    "Apia": undefined,
    "Johannesburg": undefined,
    "Antigua": undefined,
    "Anguilla": undefined,
    "Luanda": undefined,
    "McMurdo": undefined,
    "DumontDUrville": undefined,
    "Syowa": undefined,
    "Aruba": undefined,
    "Mariehamn": undefined,
    "Sarajevo": undefined,
    "Ouagadougou": undefined,
    "Bahrain": undefined,
    "Bujumbura": undefined,
    "Porto-Novo": undefined,
    "St_Barthelemy": undefined,
    "Kralendijk": undefined,
    "Nassau": undefined,
    "Gaborone": undefined,
    "Blanc-Sablon": undefined,
    "Atikokan": undefined,
    "Creston": undefined,
    "Kinshasa": undefined,
    "Lubumbashi": undefined,
    "Bangui": undefined,
    "Brazzaville": undefined,
    "Douala": undefined,
    "Curacao": undefined,
    "Busingen": undefined,
    "Djibouti": undefined,
    "Dominica": undefined,
    "Asmara": undefined,
    "Addis_Ababa": undefined,
    "Libreville": undefined,
    "Grenada": undefined,
    "Guernsey": undefined,
    "Accra": undefined,
    "Banjul": undefined,
    "Conakry": undefined,
    "Guadeloupe": undefined,
    "Malabo": undefined,
    "Zagreb": undefined,
    "Isle_of_Man": undefined,
    "Jersey": undefined,
    "Phnom_Penh": undefined,
    "Comoro": undefined,
    "St_Kitts": undefined,
    "Kuwait": undefined,
    "Cayman": undefined,
    "Vientiane": undefined,
    "St_Lucia": undefined,
    "Vaduz": undefined,
    "Maseru": undefined,
    "Podgorica": undefined,
    "Marigot": undefined,
    "Antananarivo": undefined,
    "Skopje": undefined,
    "Bamako": undefined,
    "Saipan": undefined,
    "Nouakchott": undefined,
    "Montserrat": undefined,
    "Blantyre": undefined,
    "Niamey": undefined,
    "Muscat": undefined,
    "Kigali": undefined,
    "St_Helena": undefined,
    "Ljubljana": undefined,
    "Longyearbyen": undefined,
    "Bratislava": undefined,
    "Freetown": undefined,
    "San_Marino": undefined,
    "Dakar": undefined,
    "Mogadishu": undefined,
    "Lower_Princes": undefined,
    "Mbabane": undefined,
    "Lome": undefined,
    "Port_of_Spain": undefined,
    "Dar_es_Salaam": undefined,
    "Kampala": undefined,
    "Midway": undefined,
    "Vatican": undefined,
    "St_Vincent": undefined,
    "Tortola": undefined,
    "St_Thomas": undefined,
    "Aden": undefined,
    "Mayotte": undefined,
    "Lusaka": undefined,
    "Harare": undefined
};

const FORMATS = {
    "eur": "%d€",
    "usd": "$%d",
    "rub": "₽%d"
}

/**
 * @returns {string}
 */
export function calculatePreferredCurrencyByTimezone() {
    if (Intl) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const  tzArr = userTimeZone.split("/");
        const userCity = tzArr[tzArr.length - 1];
        const result = COUNTRIES[userCity];
        return result ?? 'USD'; //default is usd
    } else {
        switch(location.pathname.split('/')[1].toLowerCase()) {
            case 'ru':
                return 'RUB';
            case 'en':
                return 'USD';
            default:
                return 'EUR';
        }
    }
}

/**
 * @param {string} currency 
 * @param {number} amount 
 * @returns {string}
 */
export function calculatePriceStringForCurrency(currency, amount) {
    const pattern = FORMATS[currency.toLowerCase()] ?? FORMATS['usd'];
    return pattern.replace('%d', (amount % 1)? amount.toFixed(2) : amount.toString());
}

export function calculateCurrencyPattern(currency) {
    currency = currency?.toUpperCase();
    for(const [city, data] of Object.entries(COUNTRIES)) {
        if(data?.currency === currency && typeof data.format === 'string') {
            return data.format;
        }
    }

    return `%d ${currency}`;
}