export default function createCss() {
        const style = new CSSStyleSheet();
        style.replaceSync(`/*! CSS Used from: http://localhost/content/pages/homepage/summary.css */
*,::after,::before{box-sizing:border-box;}
a{color:rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));text-decoration:underline;}
a:hover{--bs-link-color-rgb:var(--bs-link-hover-color-rgb);}
.d-flex{display:flex!important;}
.m-auto{margin:auto!important;}
*{margin:0;padding:0;box-sizing:border-box;}
a,a:active,a:focus{color:#575a7b;text-decoration:none;transition-timing-function:ease-in-out;-ms-transition-timing-function:ease-in-out;-moz-transition-timing-function:ease-in-out;-webkit-transition-timing-function:ease-in-out;-o-transition-timing-function:ease-in-out;transition-duration:.2s;-ms-transition-duration:.2s;-moz-transition-duration:.2s;-webkit-transition-duration:.2s;-o-transition-duration:.2s;}
a:hover,a:active{color:#1f194c;text-decoration:none;}
a:focus,a:active{outline:none;}
::selection{color:#000;background:#dbdbdb;}
a:hover,a:active,a:focus{color:#292dc2;text-decoration:none;}
.scroll-to-top{font-size:20px;text-align:center;color:#fff;text-decoration:none;position:fixed;bottom:20px;right:20px;display:none;border-radius:50%;background:#000;border:1px solid #2a2a2a;width:35px;height:35px;line-height:30px;z-index:9999;outline:0;-webkit-transition:all .3s ease;-moz-transition:all .3s ease;-o-transition:all 0.3s ease;}
.scroll-to-top i{color:#fff;}
.scroll-to-top:hover{color:#1d184a;background:#fff;}
.scroll-to-top:hover i{color:#1d184a;}
.scroll-to-top:visited{color:#1d184a;text-decoration:none;}
/*! CSS Used from: http://localhost/public/frontend/css/bootstrap453.min.css */
*,::after,::before{box-sizing:border-box;}
a{color:#007bff;text-decoration:none;background-color:transparent;}
a:hover{color:#0056b3;text-decoration:underline;}
.d-flex{display:-ms-flexbox!important;display:flex!important;}
.m-auto{margin:auto!important;}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
/*! CSS Used from: http://localhost/content/pages/homepage/homepage.css */
.scroll-to-top .fa-angle-up::before{content:'';background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z'/%3E%3C/svg%3E");width:18px;height:21px;background-size:100% 100%;display:block;}
.scroll-to-top{z-index:3;}
.scroll-to-top i{filter:invert(1);}
.scroll-to-top:hover i{filter:invert(8%) sepia(40%) saturate(4300%) hue-rotate(237deg) brightness(98%) contrast(97%);}
.scroll-to-top.hidden {bottom: 0px;transform: translateY(100%);}`);
        return style;
    }